const noop = () => {}

export class IFrameEventHandler {
  constructor() {
    this.handlers = {
      standard: new Map(),
      unknown: noop
    }
  }

  addHandler(eventType, eventHandler) {
    this.handlers.standard.set(eventType, eventHandler)
    return this
  }

  unknown(unknownEventHandler) {
    this.handlers.unknown = unknownEventHandler || noop
    return this
  }

  handle(eventType, event) {
    // eslint-disable-next-line no-console
    console.debug('handling event', { type: eventType, payload: event })
    const handler =
      this.handlers.standard.get(eventType) || this.handlers.unknown
    handler(event)
  }

  dispatch(source, event) {
    if (source && Object.prototype.hasOwnProperty.call(source, 'postMessage')) {
      // eslint-disable-next-line no-console
      console.debug('dispatching event to source', event)
      source.postMessage(event, '*')
    } else {
      // eslint-disable-next-line no-console
      console.debug(
        'skipping event dispatching, because source is invalid',
        source
      )
    }
  }
}
