import { useRef } from 'react'

export function usePromise(resolvedWith = undefined) {
  const p = useRef({
    reset() {
      p.current.promise = new Promise((resolve, reject) => {
        p.current.resolve = resolve
        p.current.reject = reject
      })
    }
  })

  p.current.reset()

  if (resolvedWith !== undefined) {
    p.current.resolve(resolvedWith)
  }

  return p
}
