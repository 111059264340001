const SortCriteria = {
  Weight: {
    PRIORITY: 1000,
    EXEMPTION: 100
  }
}

export class FlightListUtil {
  static sortByEntryTime(a, b) {
    if (a.entry.time < b.entry.time) return -1
    if (a.entry.time > b.entry.time) return 1
    return 0
  }

  static sortCrossByEntryTime(flights) {
    return flights.map((flight) => {
      const newFlight = flight
      if (flight.cross && flight.cross.length > 1) {
        newFlight.cross.sort(FlightListUtil.sortByEntryTime)
      }
      return newFlight
    })
  }

  static sortByTags(flight1, flight2) {
    let value1 = 0
    let value2 = 0

    if (flight1.priority) value1 -= SortCriteria.Weight.PRIORITY
    if (flight2.priority) value2 += SortCriteria.Weight.PRIORITY

    if (flight1.stsExempted) value1 -= SortCriteria.Weight.EXEMPTION
    if (flight2.stsExempted) value2 += SortCriteria.Weight.EXEMPTION

    const diff = value1 + value2
    if (diff !== 0) {
      return diff
    }

    const tags1 = flight1.custom_tags
    const tags2 = flight2.custom_tags
    if (tags1 && tags2) {
      if (tags1.length && tags2.length) {
        const tagsDiff = tags1.join('').localeCompare(tags2.join(''))
        if (tagsDiff !== 0) {
          return tagsDiff
        }
      } else if (tags1.length) {
        return value2
          ? 1 /* both flights are priority and/or exemption: no custom tags comes first */
          : -1 /* none of the flights have non-custom tags: custom tags comes first */
      } else if (tags2.length) {
        // the same rules as above applies here, but in reverse order
        return value2 ? -1 : 1
      }
    }

    return flight1.eobt - flight2.eobt
  }
}
