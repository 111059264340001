import moment from 'moment'
import { isValidTimeFormat } from './TimeUtils'
import {
  CommonValidationMessages,
  GdpValidationMessages,
  Time
} from './constants'

export const validateFlightLevel = (fl) => {
  if (fl === '' || Number.isNaN(fl) || !Number.isInteger(Number(fl))) {
    return {
      validFl: false,
      validFlMessage: CommonValidationMessages.FL_NUMBER
    }
  }
  if (fl < 0) {
    return {
      validFl: false,
      validFlMessage: CommonValidationMessages.FL_POSITIVE
    }
  }
  if (fl > 999) {
    return {
      validFl: false,
      validFlMessage: CommonValidationMessages.FL_MAX_NUMBERS
    }
  }
  return { validFl: true, validFlMessage: '' }
}

export const validateFlightLevels = ({ flMax, flMin }) => {
  if (flMax < flMin) {
    return {
      validFl: false,
      validFlMessage: CommonValidationMessages.FL_MAX_LOWER_THAN_MIN
    }
  }
  return { validFl: true, validFlMessage: '' }
}

export const validateRate = (rate) => {
  if (rate !== '' && rate >= 0 && rate != null) {
    return { validRate: true, validRateMessage: '' }
  }
  return {
    validRate: false,
    validRateMessage: GdpValidationMessages.RATE_INVALID
  }
}

export const validateMIT = (milesInTrails) => {
  if ((milesInTrails !== '' && milesInTrails >= 1) || milesInTrails == null) {
    return { validMIT: true, validMITMessage: '' }
  }
  return {
    validMIT: false,
    validMITMessage: GdpValidationMessages.MIT_INVALID
  }
}

export const validateSector = (sector) => {
  if (sector !== '') {
    return { validSector: true, validSectorMessage: '' }
  }
  return {
    validSector: false,
    validSectorMessage: GdpValidationMessages.TFV_INVALID
  }
}

export const validateTime = (time) => {
  if (!isValidTimeFormat(time, 'HHmm') && !isValidTimeFormat(time, 'HH:mm')) {
    return {
      validTime: false,
      validTimeMessage: CommonValidationMessages.TIME_INVALID_FORMAT
    }
  }
  return { validTime: true, validTimeMessage: '' }
}

export const validateTimeFormat = (time, format) =>
  !isValidTimeFormat(time, format)
    ? CommonValidationMessages.TIME_INVALID_FORMAT
    : undefined

export const validateDate = (date) => {
  if (!isValidTimeFormat(date, 'DD/MM/YYYY')) {
    return {
      validDate: false,
      validDateMessage: CommonValidationMessages.DATE_INVALID_FORMAT
    }
  }
  return { validDate: true, validDateMessage: '' }
}

export const validateFromToTimeDiff = (fromTime, toTime) => {
  const from = moment(fromTime).valueOf()
  const to = moment(toTime).valueOf()
  const timeDiff = Math.ceil(to - from)
  if (timeDiff < 0) {
    return {
      validTimeDiff: false,
      validTimeDiffMessage: CommonValidationMessages.TO_SMALLER_FROM_ERROR
    }
  }
  if (timeDiff / Time.MINUTE_IN_MILISECONDS < 20) {
    return {
      validTimeDiff: false,
      validTimeDiffMessage: GdpValidationMessages.TIME_DIFF_ERROR_TOO_SHORT
    }
  }
  if (timeDiff / Time.HOUR_IN_MILISECONDS > 6) {
    return {
      validTimeDiff: false,
      validTimeDiffMessage: GdpValidationMessages.TIME_DIFF_ERROR_TOO_LONG
    }
  }
  return { validTimeDiff: true, validTimeDiffMessage: '' }
}
