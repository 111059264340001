import React from 'react'
import PropTypes from 'prop-types'

import { AtfmCommonMenu, MenuStyledItem } from './common/menu'


export const AtfmMenu = ({ anchorEl, handleOpen, handleMouseDown, id, items, children, anchorOriginVertical,
  anchorOriginHorizontal, transformOriginVertical, transformOriginHorizontal, disableDivider }) => {
    let divider;
    const menuItems = items.map( (item, index) => {
      divider = !disableDivider && (index !== (items.length-1));
      return <MenuStyledItem
        divider={divider}
        onClick={item[1]}
        key={index}
      >
        {item[0]}
      </MenuStyledItem>
    });

  return (
    <div id='menu'>
      <AtfmCommonMenu
        anchorEl={anchorEl}
        handleOpen={handleOpen}
        handleMouseDown={handleMouseDown} 
        id={id}
        items={items}
        children={children}
        anchorOriginVertical={anchorOriginVertical}
        anchorOriginHorizontal={anchorOriginHorizontal}
        transformOriginVertical={transformOriginVertical}
        transformOriginHorizontal={transformOriginHorizontal}
        menuItems={menuItems}
      />
    </div>
  );
}

AtfmMenu.propTypes = {
  handleOpen: PropTypes.bool.isRequired,
  handleMouseDown: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};