import jwtDecode from 'jwt-decode'

export class JwtTokenUtils {
  static getLoginEventFromJwt(user, executionEnv) {
    if (user && user.access_token) {
      const decodedToken = jwtDecode(user.access_token)
      return {
        name: 'login',
        payload: {
          ...this.getAuthenticationDataFromParsedToken(decodedToken),
          ...this.getCdmDataFromParsedToken(decodedToken, executionEnv)
        }
      }
    }
    return null
  }

  static getAuthenticationDataFromParsedToken(token) {
    return {
      name: token.name || token.preferred_username || token.email,
      username: token.preferred_username,
      email: token.email
    }
  }

  static getCdmDataFromParsedToken(token, executionEnv) {
    return {
      cdmUserAccessToken: token[`cdm_${executionEnv}_user_access_token`],
      cdmPublishMeasuresChannelId:
        token[`cdm_${executionEnv}_publish_measures_channel_id`]
    }
  }
}
