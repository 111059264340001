export const range = (start, end, inside) =>
  Array(end - start + 1)
    .fill()
    .map((_, v) => inside(start + v))

export const clamp = (nr, min, max) => Math.min(Math.max(nr, min), max)

export const ceilToNearestFactor = (value, factor) =>
  Math.ceil(value / factor) * factor

export const roundToNearestFactor = (value, factor) =>
  Math.round(value / factor) * factor
